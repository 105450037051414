<template>
  <div>
    <div class="row justify-content-between no-gutters mb-2">
      <div
        style="width: 300px"
        class="order-md-3 d-none d-md-block "
      />
      <div class="col-lg-12 col-xl-auto order-md-2 ml-2">
        <PlantBreadcrumb
          breadcrumb="line"
          class="mt-2"
          :element-id="lineId"
        />
        <SubPageSelector
          :list="routes"
          :selected="selected"
          class="mt-3"
          @selected="changePage"
        />
      </div>
      <div
        class="col-12 col-md-auto order-md-1 py-2 mr-4"
        style="width: 300px"
      >
        <DateScopeDropdown
          v-show="showDateScope"
        />
      </div>
    </div>

    <slot @pageChange="selected = $event">
      <RouterView
        name="header"
        class="header"
      />
      <RouterView @pageChange="selected = $event" />
    </slot>
  </div>
</template>

<script>
import DateScopeDropdown from '@/layout/DateScopeDropdown';
import PlantBreadcrumb from '@/layout/dashboard/PlantBreadcrumb';
import SubPageSelector from '@/layout/dashboard/selectors/SubPageSelector';
import { mapGetters } from 'vuex';
import moment from 'moment/moment';

export default {
  props: {
    lineId: {
      type: String,
      required: true,
    },
    selectedPage: String,
  },
  data: () => ({
    selected: null,
  }),
  components: {
    SubPageSelector,
    DateScopeDropdown,
    PlantBreadcrumb,
  },
  computed: {
    ...mapGetters([
      'plantId',
      'dayStartOffsetInUserTimezone',
    ]),
    ...mapGetters('core', [
      'canOnElement',
    ]),
    ...mapGetters('plant', [
      'plantProperty',
      'structure',
      'hideDowntimesButton',
    ]),
    showDateScope() {
      return this.selected !== 'worklogs' && this.selected !== 'wall';
    },
    dayStart() {
      const day = moment().format('YYYY-MM-DD');
      return moment(day, 'YYYY-MM-DD')
        .add(this.dayStartOffsetInUserTimezone, 'seconds')
        .unix();
    },
    dayEnd() {
      return moment.unix(this.dayStart).add(1, 'day').unix();
    },
    visionRouteUrl() {
      const startFromQuery = this.$route.query?.start;
      const endFromQuery = this.$route.query?.end;
      const start = startFromQuery || this.dayStart;
      const timelineSeconds = endFromQuery
        ? endFromQuery - startFromQuery
        : Math.min(this.$now, this.dayEnd) - this.dayStart;
      const params = new URLSearchParams({
        elementId: this.lineId,
        startDate: start,
        rootDate: start,
        playerTime: start,
        calendarRootDate: moment.unix(start).format('YYYY-MM-DD HH:mm'),
        timelineSeconds,
      });

      return `/${this.plantId}/vision?${params.toString()}`;
    },
    routes() {
      const { plantId, lineId } = this;
      return [
        {
          key: 'machines',
          name: this.$t('machines'),
          route: `/${plantId}/dashboard/line/${lineId}/machines`,
          icon: 'fas fa-cogs',
          show: true,
        },
        {
          key: 'worklogs',
          name: this.$t('work.spectrum'),
          route: `/${plantId}/dashboard/line/${lineId}/worklogs`,
          icon: 'fas fa-history',
          show: true,
        },
        {
          key: 'shifts',
          name: this.$t('shifts'),
          route: `/${plantId}/dashboard/line/${lineId}/shifts`,
          icon: 'ion ion-ios-body',
          show: true,
        },
        {
          key: 'wall',
          name: this.$t('work.downtimes'),
          route: `/${plantId}/dashboard/line/${lineId}/worklogs/${lineId}`,
          icon: 'icon-downtime',
          show: !this.hideDowntimesButton,
        },
        {
          key: 'vision',
          name: this.$t('menu.lineView'),
          route: this.visionRouteUrl,
          icon: 'fas fa-file-video',
          openInNewTab: true,
          show: true,
        },
      ].filter(x => x.show);
    },
  },
  watch: {
    selectedPage(p) {
      this.selected = p;
    },
  },
  methods: {
    changePage({ key, route }) {
      this.selected = key;
      this.$router.push(route);
    },
  },
  created() {
    this.selected = this.selectedPage;
  },
};
</script>

<style scoped>
  .header {
    border-bottom: 5px solid rgba(180, 180, 180, 0.1);
    margin: 0 -15px;
  }

  @media screen and (max-width: 768px) {
    .header {
      border-bottom: 10px solid rgba(126, 126, 126, 0.2);
    }
  }
</style>
