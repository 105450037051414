<template>
  <Chartist
    ref="chartist"
    :data="chartData"
    :options="chartOptions"
    :event-handlers="eventHandlers"
    :style="{ height: `${height}px`, maxHeight: `${maxHeight}px`}"
    ratio="ct-square"
    type="Pie"
    class="chartist-oee"
  >
    <slot>
      <div
        v-if="!hideValue"
        :style="{ 'margin-top': label ? '-1.1em' : '' }"
        :class="size"
        class="OEEpercent donut-label text-center"
      >
        <span v-if="value || value === 0 || value === '0'">
          {{ chartValue }}%
        </span>
        <span v-else>
          0%
        </span>
      </div>

      <div
        v-if="label"
        :class="size"
        :style="`font-size: ${fontSize}px`"
        class="OEEpercent donut-label-text text-nowrap text-center"
      >
        {{ label }}
      </div>
    </slot>
  </Chartist>
</template>

<script>
import tinycolor from 'tinycolor2';
import { mapGetters } from 'vuex';

export default {
  props: {
    value: Number,
    label: String,
    size: String,
    target: Number,
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    roundTo: {
      type: Number,
      default: 1,
    },
    hideValue: Boolean,
    mono: Boolean,
    maxHeight: Number,
  },
  computed: {
    ...mapGetters(['screenWidth']),
    chartValue() {
      if (this.value) {
        if (this.value > 0 && this.value <= 1) return 1;
        return this.round(this.value);
      }

      return 0;
    },
    seriesValue() {
      const full = this.max - this.min;
      return (Math.max(this.min, Math.min(this.chartValue - this.min, this.max) / full)) * 100;
    },
    targetValue() {
      const t = this.target ? Math.min(this.target, this.max) : this.max * 0.8;
      return (t / this.max) * 100;
    },
    chartData() {
      const { seriesValue, targetValue } = this;

      if (!this.target) {
        return {
          series: [
            seriesValue,
            0,
            100 - seriesValue,
          ],
          labels: ['', '', ''],
        };
      }

      if (targetValue > seriesValue) {
        return {
          series: [
            seriesValue,
            targetValue - seriesValue,
            100 - targetValue,
          ],
          labels: ['', '', ''],
        };
      }

      return {
        series: [
          targetValue,
          seriesValue - targetValue,
          100 - seriesValue,
        ],
        labels: ['', '', ''],
      };
    },
    chartOptions() {
      return {
        donut: true,
        donutWidth: this.donutWidth,
        startAngle: 210,
        total: 120,
        showLabel: false,
      };
    },
    donutWidth() {
      switch (this.size) {
        case 'xs':
          return 4;
        case 'sm':
          return 4;
        case 'lg':
          return 17;
        default:
          return 5;
      }
    },
    height() {
      switch (this.size) {
        case 'xs':
          return 65;
        case 'sm':
          return 80;
        default:
          return null;
      }
    },
    eventHandlers() {
      return [{
        event: 'draw',
        fn: ctx => {
          if (ctx.index > 2) return;
          if (ctx.index === 2) {
            ctx.element.attr({
              style: `stroke: #eaeaea;
                      stroke-width: ${this.donutWidth}px;`,
            });
            return;
          }

          if (ctx.index === 0) {
            ctx.element.attr({
              style: `stroke: ${this.getColor(this.chartValue)};
                    stroke-width: ${this.donutWidth}px;`,
            });
            return;
          }

          if (this.targetValue > this.seriesValue) {
            ctx.element.attr({
              style: `stroke: rgba(100, 100, 100, 0.5);
                      stroke-width: ${this.donutWidth}px;`,
            });
          } else {
            ctx.element.attr({
              style: `stroke: ${this.getLighterColor(this.chartValue)};
                      stroke-width: ${this.donutWidth}px;`,
            });
          }
        },
      }];
    },
    fontSize() {
      const sizes = [this.height, this.maxHeight].filter(x => x);
      if (sizes.length === 0) return 12;
      const fontSize = Math.min(...sizes) * 0.125;
      if (fontSize < 10) return 8;
      if (fontSize > 18) return 18;
      return fontSize;
    },
  },
  watch: {
    screenWidth() {
      this.redraw();
    },
  },
  methods: {
    getColor(v) {
      const val = this.round(v);
      if (this.mono) return this.$color('blue');
      const low = 0.3 * this.targetValue;
      const high = 1 * this.targetValue;

      if (val >= high) {
        return this.$color('work');
      }
      if (val < high && val >= low) {
        return this.$color('orange');
      }
      return this.$color('downtime');
    },
    getLighterColor(val) {
      return tinycolor(this.getColor(val)).darken(15).toString();
    },
    round(v) {
      const mult = 10 ** this.roundTo;
      return Math.max(0, Math.min(999, Math.round(v * mult) / mult));
    },
    redraw() {
      window.setTimeout(() => {
        if (this.$refs.chartist) this.$refs.chartist.redraw();
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
  .chartist-oee {
    .OEEpercent {
      font-weight: bold;
      font-size: 18px;
      left: 2px;
      position: relative;
      z-index: 2;

      &.xxs {
        font-size: 10px;
      }
      &.xs {
        font-size: 12px;
      }
      &.sm {
        font-size: 14px;
      }
    }
    .donut-label, .donut-label-text {
      position: absolute;
      top: 50%;
      margin-top: -0.75em;
      width: 100%;
      left: 0;
      display: table-cell;
      vertical-align: middle;
    }

    .donut-label-text {
      // margin-top: 0.25em;
      margin-top: 0.4em;
      font-weight: 300;
    }
  }

</style>
